import React, { useState, useEffect } from "react"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import LangSwitcher from "./LangSwitcher"
import styled from "@emotion/styled"
import logo from "../../static/logo/new-tsb.svg"
import { Row, Menu, Dropdown, Button, Popover } from "antd"
import { DownOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons"

const { SubMenu } = Menu
const HeaderWrapper = styled.header`
  width: 100%;
  padding: 20px 0;
  z-index: 999;
  /*position: absolute;
  top: 0;
  left: 0;*/
  transition: visibility 0.05s ease-in-out;
  background-color: #181818;
  .siteLogo {
    max-height: 60px;
    img {
      max-height: 60px;
      width: auto;
    }
  }
  #site__navbar {
    list-style: none;
    line-height: 38px;
    border-bottom: none;
    background: transparent;
    display: none;
    align-items: center;
    margin-bottom: 0;
    padding: 0;
  }
  .ctaWrapper {
    margin-left: 20px;
  }
  .mobileMenuWrapper {
    display: block;
    button {
      background: #fff;
      border-color: #fff;
      width: 38px;
      height: 32px;
      svg {
        width: 32px;
        height: 20px;
      }
    }
  }
  @media only screen and (min-width: 576px) {
    .siteLogo {
      max-height: 65px;
      img {
        max-height: 65px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .siteLogo {
      max-height: 100px;
      img {
        max-height: 100px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    #site__navbar {
      display: flex;
    }
    .mobileMenuWrapper {
      display: none;
    }
  }
`
const LiWrapper = styled.li`
  display: inline-flex;
  align-items: center;
  border-bottom: none !important;
  max-height: 38px;
  padding: 0 18px;
  white-space: nowrap;
  a {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #fff;
      .anticon {
        color: #fff;
        svg {
          fill: #fff;
        }
      }
    }
    .anticon {
      color: #fff;
      svg {
        fill: #fff;
      }
    }
  }
  /*&.active {
    background-color: #7497b2;
    border-radius: 5px;
    a {
      color: #fff;
      &:hover {
        color: #fff;
        .anticon {
          color: #fff;
          svg {
            fill: #fff;
          }
        }
      }
      .anticon {
        color: #fff;
        svg {
          fill: #fff;
        }
      }
    }
  }*/
  .langLinkWrapper {
    img {
      width: 18px;
      min-width: 18px;
      height: 18px;
      min-height: 18px;
      border-radius: 100%;
    }
    .anticon {
      margin-left: 10px;
      color: #fff;
      svg {
        fill: #fff;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 0 8px;
  }
  @media only screen and (min-width: 1440px) {
    padding: 0 12px;
    a {
      font-size: 18px;
    }
  }
  @media only screen and (min-width: 1920px) {
    padding: 0 20px;
  }
`

const HeaderColor = () => {
  const intl = useIntl()
  const [popVisible, setPopVisible] = useState(false)
  const [pathname, setPathname] = useState("home")

  // const subMenu1 = (
  //   <Menu className="subMenuWrapper">
  //     <Menu.Item key="11">
  //       <Link to="/investor-relations/#tab1" key="tab1">
  //         {intl.formatMessage({ id: "subMenu11" })}
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item key="12">
  //       <Link to="/investor-relations/#tab2">
  //         {intl.formatMessage({ id: "subMenu12" })}
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item key="13">
  //       <Link to="/investor-relations/#tab3">
  //         {intl.formatMessage({ id: "subMenu13" })}
  //       </Link>
  //     </Menu.Item>
  //   </Menu>
  // )
  /* Drowdown Menu Out  */
  const subMenu2 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="2section1">
        <Link to="/corporate-governance/anti-corruption-policy/">
          {intl.formatMessage({ id: "subMenu24" })}
        </Link>
      </Menu.Item>
      <Menu.Item key="2section2">
        <Link to="/corporate-governance/business-ethics-policy/">
          {intl.formatMessage({ id: "subMenu21" })}
        </Link>
      </Menu.Item>
      <Menu.Item key="2section3">
        <Link to="/corporate-governance/health-policy/">
          {intl.formatMessage({ id: "subMenu28" })}
        </Link>
      </Menu.Item>
      <Menu.Item key="2section4">
        <Link to="/corporate-governance/environmental-policy/">
          {intl.formatMessage({ id: "subMenu29" })}
        </Link>
      </Menu.Item>
      <Menu.Item key="2section5">
        <Link to="/corporate-governance/receive-a-complaint/">
          {intl.formatMessage({ id: "subMenu23" })}
        </Link>
      </Menu.Item>
      <Menu.Item key="2section6">
        <Link to="/corporate-governance/esg-policy/">
          {intl.formatMessage({ id: "subMenu30" })}
        </Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu3 = (
    <Menu className="subMenuWrapper" selectedKeys={[pathname]}>
      <Menu.Item key="about-us">
        <Link to="/about-us/" activeClassName="active">
          {intl.formatMessage({ id: "rootMenu3" })}
        </Link>
      </Menu.Item>
      <SubMenu
        key="rootMenu3Sub1"
        title={intl.formatMessage({ id: "rootMenu3Sub1" })}
      >
        <Menu.Item key="organization-structure">
          <Link
            to="/organization-management/organization-structure/"
            activeClassName="active"
          >
            {intl.formatMessage({ id: "rootMenu3Sub111" })}
          </Link>
        </Menu.Item>
        <Menu.Item key="board">
          <Link to="/organization-management/board/" activeClassName="active">
            {intl.formatMessage({ id: "subRootMenu312" })}
          </Link>
        </Menu.Item>
        <Menu.Item key="executive">
          <Link
            to="/organization-management/executive/"
            activeClassName="active"
          >
            {intl.formatMessage({ id: "subRootMenu313" })}
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="projects">
        <Link to="/projects/" activeClassName="active">
          {intl.formatMessage({ id: "rootMenu3Sub2" })}
        </Link>
      </Menu.Item>
      <Menu.Item key="standard-certification">
        <Link to="/standard-certification/" activeClassName="active">
          {intl.formatMessage({ id: "rootMenu3Sub3" })}
        </Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu4 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="3section1">
        <Link to="/distributor/">
          {intl.formatMessage({ id: "distributorHead" })}
        </Link>
      </Menu.Item>
    </Menu>
  )
  const mobileMenu = (
    <>
      <Menu mode="inline" defaultSelectedKeys={[pathname]}>
        <Menu.Item key="home">
          <Link to="/">{intl.formatMessage({ id: "navMenu1" })}</Link>
        </Menu.Item>
        <Menu.Item key="rootMenu1">
          <Link to="/products/">{intl.formatMessage({ id: "navMenu5" })}</Link>
        </Menu.Item>
        {/* <SubMenu
          key="rootMenu1"
          title={intl.formatMessage({ id: "rootMenu1" })}
        >
          <Menu.Item key="11">
            <Link to="/investor-relations/#tab1">
              {intl.formatMessage({ id: "subMenu11" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="12">
            <Link to="/investor-relations/#tab2">
              {intl.formatMessage({ id: "subMenu12" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="13">
            <Link to="/investor-relations/#tab3">
              {intl.formatMessage({ id: "subMenu13" })}
            </Link>
          </Menu.Item>
        </SubMenu> */}
        <SubMenu
          key="rootMenu2"
          title={intl.formatMessage({ id: "rootMenu2" })}
        >
          <Menu.Item key="2section1">
            <Link to="/corporate-governance/anti-corruption-policy/">
              {intl.formatMessage({ id: "subMenu24" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="2section2">
            <Link to="/corporate-governance/business-ethics-policy/">
              {intl.formatMessage({ id: "subMenu21" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="2section3">
            <Link to="/corporate-governance/health-policy/">
              {intl.formatMessage({ id: "subMenu28" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="2section4">
            <Link to="/corporate-governance/environmental-policy/">
              {intl.formatMessage({ id: "subMenu29" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="2section5">
            <Link to="/corporate-governance/receive-a-complaint/">
              {intl.formatMessage({ id: "subMenu23" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="2section6">
            <Link to="/corporate-governance/esg-policy/">
              {intl.formatMessage({ id: "subMenu30" })}
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="environmental-care">
          <Link to="/environmental-care/">
            {intl.formatMessage({ id: "navMenu2" })}
          </Link>
        </Menu.Item>
        <SubMenu
          key="rootMenu3"
          title={intl.formatMessage({ id: "rootMenu3" })}
        >
          <Menu.Item key="about-us">
            <Link to="/about-us/">
              {intl.formatMessage({ id: "rootMenu3" })}
            </Link>
          </Menu.Item>
          <SubMenu
            key="rootMenu3Sub1"
            title={intl.formatMessage({ id: "rootMenu3Sub1" })}
          >
            <Menu.Item key="organization-structure">
              <Link to="/organization-management/organization-structure/">
                {intl.formatMessage({ id: "rootMenu3Sub111" })}
              </Link>
            </Menu.Item>
            <Menu.Item key="board">
              <Link to="/organization-management/board/">
                {intl.formatMessage({ id: "subRootMenu312" })}
              </Link>
            </Menu.Item>
            <Menu.Item key="executive">
              <Link to="/organization-management/executive/">
                {intl.formatMessage({ id: "subRootMenu313" })}
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="projects">
            <Link to="/projects/">
              {intl.formatMessage({ id: "rootMenu3Sub2" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="standard-certification">
            <Link to="/standard-certification/">
              {intl.formatMessage({ id: "rootMenu3Sub3" })}
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="news">
          <Link to="/news/">{intl.formatMessage({ id: "navMenu3" })}</Link>
        </Menu.Item>
        <SubMenu
          key="contact-us"
          title={intl.formatMessage({ id: "navMenu4" })}
        >
          <Menu.Item key="3section2">
            <Link to="/contact-us/">
              {intl.formatMessage({ id: "navMenu4" })}
            </Link>
          </Menu.Item>
          <Menu.Item key="3section1">
            <Link to="/distributor/">
              {intl.formatMessage({ id: "distributorHead" })}
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
      <LangSwitcher />
    </>
  )
  const handleVisibleChange = visible => {
    setPopVisible(visible)
  }

  useEffect(() => {
    if (window !== undefined) {
      if (
        window.location.pathname === "/" ||
        window.location.pathname === "/en/"
      ) {
        setPathname("home")
      } else {
        setPathname(window.location.pathname)
      }
    }
  }, [])

  return (
    <div id="site__header__wrapper">
      <HeaderWrapper
        id="site__header"
        style={{ backgroundColor: popVisible ? `#425766` : `#181818` }}
      >
        <div className="gb-container">
          <Row justify="space-between" align="middle">
            <Link to="/" className="siteLogo" aria-label="Site logo">
              <img src={logo} width="159" height="118" alt="T.S.B.STEEL" />
            </Link>
            <ul id="site__navbar">
              <LiWrapper
                key="navMenu1"
                className={pathname === `home` ? `active` : ``}
              >
                <Link to="/">{intl.formatMessage({ id: "navMenu1" })}</Link>
              </LiWrapper>
              <LiWrapper
                key="subMenu1"
                className={
                  pathname === `/products/` || pathname === `/en/products/`
                    ? `active`
                    : ``
                }
              >
                <Link to="/products/">
                  {intl.formatMessage({ id: "navMenu5" })}
                </Link>
                {/* <Dropdown overlay={subMenu1}>
                  <Link to="/investor-relations/" className="ant-dropdown-link">
                    {intl.formatMessage({ id: "rootMenu1" })} <DownOutlined />
                  </Link>
                </Dropdown> */}
              </LiWrapper>
              <LiWrapper
                key="subMenu2"
                className={
                  pathname === `/corporate-governance/` ||
                  pathname === `/en/corporate-governance/`
                    ? `active`
                    : ``
                }
              >
                <Dropdown overlay={subMenu2}>
                  <Link
                    to="/corporate-governance/"
                    className="ant-dropdown-link"
                  >
                    {intl.formatMessage({ id: "rootMenu2" })} <DownOutlined />
                  </Link>
                </Dropdown>
              </LiWrapper>
              <LiWrapper
                key="navMenu2"
                className={
                  pathname === `/environmental-care/` ||
                  pathname === `/en/environmental-care/`
                    ? `active`
                    : ``
                }
              >
                <Link to="/environmental-care/">
                  {intl.formatMessage({ id: "navMenu2" })}
                </Link>
              </LiWrapper>
              <LiWrapper
                key="subMenu3"
                className={
                  pathname === `/about-us/` ||
                  pathname === `/en/about-us/` ||
                  pathname ===
                    `/organization-management/organization-structure/` ||
                  pathname ===
                    `/en/organization-management/organization-structure/` ||
                  pathname === `/organization-management/board/` ||
                  pathname === `/en/organization-management/board/` ||
                  pathname === `/organization-management/executive/` ||
                  pathname === `/en/organization-management/executive/` ||
                  pathname === `/projects/` ||
                  pathname === `/en/projects/` ||
                  pathname === `/standard-certification/` ||
                  pathname === `/en/standard-certification/`
                    ? `active`
                    : ``
                }
              >
                <Dropdown overlay={subMenu3}>
                  <a
                    href="#void"
                    className="ant-dropdown-link"
                    onClick={e => e.preventDefault()}
                  >
                    {intl.formatMessage({ id: "rootMenu3" })} <DownOutlined />
                  </a>
                </Dropdown>
              </LiWrapper>
              <LiWrapper
                key="navMenu3"
                className={
                  pathname === `/news/` || pathname === `/en/news/`
                    ? `active`
                    : ``
                }
              >
                <Link to="/news/">
                  {intl.formatMessage({ id: "navMenu3" })}
                </Link>
              </LiWrapper>
              <LiWrapper
                key="navMenu4"
                className={
                  pathname === `/contact-us/` || pathname === `/en/contact-us/`
                    ? `active`
                    : ``
                }
              >
                <Dropdown overlay={subMenu4}>
                  <Link
                    to="/contact-us/"
                    className="ant-dropdown-link"
                  >
                    {intl.formatMessage({ id: "navMenu4" })} <DownOutlined />
                  </Link>
                </Dropdown>
              </LiWrapper>
              <LiWrapper key="languages">
                <LangSwitcher />
              </LiWrapper>
            </ul>
            <div className="mobileMenuWrapper">
              <Popover
                placement="bottomRight"
                content={mobileMenu}
                trigger="click"
                visible={popVisible}
                onVisibleChange={handleVisibleChange}
              >
                <Button
                  type="primary"
                  icon={popVisible ? <CloseOutlined /> : <MenuOutlined />}
                  onClick={handleVisibleChange}
                  style={{ fontSize: "18px", color: "#425766" }}
                />
              </Popover>
            </div>
          </Row>
        </div>
      </HeaderWrapper>
    </div>
  )
}

export default HeaderColor
