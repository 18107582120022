import React from "react"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import options from "../../config/options"
import { Row, Col } from "antd"
import styled from "@emotion/styled"
import logo from "../../static/logo/new-tsb.svg"
import iconLocation from "../../static/assets/images/icon-location.svg"
import iconMail from "../../static/assets/images/icon-mail.svg"
import iconPhone from "../../static/assets/images/icon-phone.svg"

const SectionWrapper = styled.div`
  width: 100%;
  padding-top: 60px;
  background: #7497b2;
  .siteLogo {
    max-height: 78px;
    img {
      max-height: 78px;
      width: auto;
    }
  }
  .colTitle {
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
  }
  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 10px;
      a {
        font-family: "Kanit", sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        &:hover {
          color: #181818;
        }
      }
    }
  }
  .contentWrapper {
    padding-left: 0;
    .social {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        margin-bottom: 10px;
        .icon {
          width: 22px;
          height: 22px;
          margin-top: 3px;
          margin-right: 10px;
          img {
            min-width: 22px;
            width: 22px;
            min-height: 22px;
            height: 22px;
          }
        }
        p {
          font-family: "Kanit", sans-serif;
          font-weight: 400;
          font-size: 18px;
          color: #fff;
          margin-bottom: 0;
        }
        a {
          font-family: "Kanit", sans-serif;
          font-weight: 400;
          font-size: 18px;
          color: #fff;
          &:hover {
            color: #181818;
          }
        }
      }
    }
  }
  .hr {
    width: 100%;
    height: 0;
    margin: 30px 0;
    border: 1px solid #f8f8f8;
  }
  .copyright {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    padding-bottom: 35px;
    margin-bottom: 0;
    text-align: center;
  }

  @media only screen and (min-width: 576px) {
    .siteLogo {
      max-height: 82px;
      img {
        max-height: 82px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .siteLogo {
      max-height: 120px;
      img {
        max-height: 120px;
      }
    }
    .contentWrapper {
      padding-left: 30px;
      .social {
        margin: 0 0 0 -30px;
      }
    }
  }
`

const Footer = () => {
  const intl = useIntl()

  return (
    <SectionWrapper>
      <div className="gb-container">
        <Row gutter={[16, 32]}>
          <Col xs={24} sm={24} lg={6}>
            <Link to="/" className="siteLogo" aria-label="Site logo">
              <img src={logo} height="118" alt="T.S.B.STEEL" />
            </Link>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <h4 className="colTitle">
              {intl.formatMessage({ id: "textPages" })}
            </h4>
            <ul className="menu">
              <li>
                <Link to="/">{intl.formatMessage({ id: "navMenu1" })}</Link>
              </li>
              <li>
                <Link to="/#/">{intl.formatMessage({ id: "rootMenu1" })}</Link>
              </li>
              <li>
                <Link to="/corporate-governance/">
                  {intl.formatMessage({ id: "rootMenu2" })}
                </Link>
              </li>
              <li>
                <Link to="/environmental-care/">
                  {intl.formatMessage({ id: "navMenu2" })}
                </Link>
              </li>
              <li>
                <Link to="/about-us/">
                  {intl.formatMessage({ id: "rootMenu3" })}
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <h4 className="colTitle">
              {intl.formatMessage({ id: "textPages" })}
            </h4>
            <ul className="menu">
              <li>
                <Link to="/news/">
                  {intl.formatMessage({ id: "navMenu3" })}
                </Link>
              </li>
              <li>
                <Link to="/products/">
                  {intl.formatMessage({ id: "navMenu5" })}
                </Link>
              </li>
              <li>
                <Link to="/contact-us/">
                  {intl.formatMessage({ id: "navMenu4" })}
                </Link>
              </li>
              <li>
                <Link to="/work-with-us/">
                  {intl.formatMessage({ id: "navMenu6" })}
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy/">
                  {intl.formatMessage({ id: "navMenu7" })}
                </Link>
              </li>
              <li>
                <Link to="/cookie-policy/">
                  {intl.formatMessage({ id: "navMenu8" })}
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <div className="contentWrapper">
              <h4 className="colTitle">
                {intl.formatMessage({ id: "textContact" })}
              </h4>
              <ul className="social">
                <li>
                  <span className="icon">
                    <a
                      href="https://goo.gl/maps/s4kbxGdMpuQoD6hu9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={iconLocation}
                        width="22"
                        height="22"
                        alt="Location"
                      />
                    </a>
                  </span>
                  <a
                    href="https://goo.gl/maps/s4kbxGdMpuQoD6hu9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: "companyAddressFooter" })}
                  </a>
                </li>
                <li>
                  <Link to="/contact-us/">
                    <span className="icon">
                      <img src={iconMail} width="22" height="22" alt="Mail" />
                    </span>
                  </Link>
                  <Link to="/contact-us/">
                    {intl.formatMessage({ id: "navMenu4" })}
                  </Link>
                </li>
                <li>
                  <span className="icon">
                    <a
                      href={options.telCTA}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={iconPhone} width="22" height="22" alt="Phone" />
                    </a>
                  </span>
                  <a
                    href={options.telCTA}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tel : {options.telText}
                  </a>
                </li>
                <li>
                  <span className="icon"></span>
                  <p>Fax : {options.faxText}</p>
                </li>
                <li>
                  <span className="icon">
                    <Link to="/distributor/">
                      <img
                        src={iconLocation}
                        width="22"
                        height="22"
                        alt="Location"
                      />
                    </Link>
                  </span>
                  <Link to="/distributor/">
                    {intl.formatMessage({ id: "distributorHead" })}
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      <div className="hr"></div>
      <div className="gb-container">
        <p className="copyright">
          COPYRIGHT © 2021 <br className="gb--visible gb--hidden--sm" />
          {intl.formatMessage({ id: "companyLimited" })}
        </p>
      </div>
    </SectionWrapper>
  )
}

export default Footer
