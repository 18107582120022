import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"
import flagEn from "../../static/assets/images/flag-en.svg"
import flagTh from "../../static/assets/images/flag-th.svg"
import { Menu, Dropdown, Button } from "antd"
import { DownOutlined } from "@ant-design/icons"

const languageImage = {
  en: flagEn,
  th: flagTh,
}

const LangSwitcher = () => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => {
        return (
          <Dropdown
            overlay={
              <Menu className="langSwitcher">
                {languages.map(language => {
                  return (
                    <Menu.Item
                      key={language}
                      className={currentLocale === language ? `selected` : ``}
                    >
                      <a
                        href={`#${language}`}
                        onClick={e => {
                          changeLocale(language)
                          e.preventDefault()
                        }}
                      >
                        <img
                          src={languageImage[language]}
                          width="18"
                          height="18"
                          alt={language}
                        />
                      </a>
                    </Menu.Item>
                  )
                })}
              </Menu>
            }
            trigger={["click"]}
          >
            <Button
              type="link"
              className="langLinkWrapper"
              onClick={e => e.preventDefault()}
            >
              {languages ? (
                languages.map(item => {
                  if (item === currentLocale) {
                    return (
                      <img
                        key={item}
                        src={languageImage[currentLocale]}
                        width="18"
                        height="18"
                        alt={currentLocale}
                      />
                    )
                  }
                  return null
                })
              ) : (
                <img src={flagTh} width="18" height="18" alt="ไทย" />
              )}
              <DownOutlined />
            </Button>
          </Dropdown>
        )
      }}
    </IntlContextConsumer>
  )
}

export default LangSwitcher
