export const options = {
  mailText: "info@tsbsteel.com",
  mailCTA: "mailto:info@tsbsteel.com",
  telText: "02 463 7884",
  telCTA: "tel:024637884",
  faxText: "02 463 7885",
  telFactoryText: "03 762 5371-2",
  telFactoryCTA: "tel:037625371",
  faxFactoryText: "03 762 5373",
}

export default options
